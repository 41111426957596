import { KeepAlive } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/home/index.vue'),
    meta: {
      KeepAlive: true,
    },
  },
  {
    path: '/room',
    name: 'room',
    component: () => import('../views/room/index.vue'),
  },
  // -------------------------------
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
